<!-- 系统消息 -->

<template>
  <div class="message-page">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <template slot="pulling">
        {{ $i18n.t("rd_pull_to_refresh") }}
      </template>
      <template slot="loosing">
        {{ $i18n.t("rd_pull_to_refresh") }}
      </template>
      <template slot="loading">
        {{ $i18n.t("rd_loading") }}
      </template>
      <van-list
        class="message-list"
        v-model="loading"
        :finished="finished"
        :finished-text="finishedText"
        @load="loadList"
      >
        <template slot="finished">
          {{ $i18n.t("rd_no_more") }}
        </template>
        <template slot="loading">
          {{ $i18n.t("rd_loading") }}
        </template>
        <div class="message-item" v-for="(item, index) in messageList" :key="index">
          <!-- <div class="msg-title">这里是系统消息标题</div> -->
          <div class="desc">{{ item.content }}</div>
          <div class="msg-time">{{ item.date }}</div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      refreshing: false,
      loading: false,
      finished: true,
      messageList: [],
    };
  },
  created() {
    this.loadList();
    this.$store.commit('modifyRead', true)
  },
  computed: {
    finishedText() {
      return this.$i18n.t("record_nomore");
    },
  },
  methods: {
    onRefresh() {
      this.loadList();
    },
    async loadList() {
      const res = await this.$http.getUserMessage();
      this.messageList = res.data.list;
      this.messageList = this.messageList.map((v) => {
        return {
          ...v,
          content: v[`enContent`] || v[`Content`] || v[`content`],
          date: moment(v.CreatedAt || new Date()).format("YYYY-MM-DD HH:mm:ss"),
        };
      });
      // 加载状态结束
      this.loading = false;
      this.finished = true;
      this.refreshing = false;
    },
  },
};
</script>

<style lang="less">
.message-page {
  padding-top: 56px;
  .message-list {
    padding: 0.48rem;
    .message-item {
      text-align: justify;
      margin-bottom: 0.48rem;
      .msg-title {
        line-height: 0.48rem;
        color: #333;
        font-size: 0.32rem;
        margin-bottom: 0.12rem;
      }
      .desc {
        color: #999;
        font-size: 0.28rem;
        line-height: 0.4rem;
        margin-bottom: 0.4rem;
      }
      .msg-time {
        color: #999;
        font-size: 0.2rem;
        line-height: 0.22rem;
      }
    }
  }
}
</style>
